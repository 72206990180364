import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cc098e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-editor-container" }
const _hoisted_2 = { class: "channel-select" }
const _hoisted_3 = { class: "chart-wrapper" }
const _hoisted_4 = { class: "demo-date-picker" }
const _hoisted_5 = { class: "block" }
const _hoisted_6 = { class: "chart-wrapper" }
const _hoisted_7 = { class: "chart-wrapper" }
const _hoisted_8 = { class: "chart-wrapper" }
const _hoisted_9 = { class: "chart-wrapper" }
const _hoisted_10 = { class: "chart-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_PanelGroup = _resolveComponent("PanelGroup")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_ChannelCampaignDetail = _resolveComponent("ChannelCampaignDetail")!
  const _component_LineChartUser = _resolveComponent("LineChartUser")!
  const _component_LineChartRevenue = _resolveComponent("LineChartRevenue")!
  const _component_PieChartUser = _resolveComponent("PieChartUser")!
  const _component_PieChartRevenue = _resolveComponent("PieChartRevenue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_select, {
        modelValue: $setup.channelId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.channelId) = $event)),
        placeholder: "Choose Channel"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.channelOptions, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.id,
              label: item.utm_source,
              value: item.id
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_PanelGroup, { channelId: $setup.channelId }, null, 8, ["channelId"]),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_DateRangePicker, {
                    disabled: "",
                    limitDayCount: 7,
                    dateRange: $setup.dateRange,
                    onChange: $setup.setDateRange
                  }, null, 8, ["dateRange", "onChange"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ChannelCampaignDetail, {
                channelId: $setup.channelId,
                dateRange: $setup.dateRange
              }, null, 8, ["channelId", "dateRange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_LineChartUser, {
                channelId: $setup.channelId,
                dateRange: $setup.dateRange
              }, null, 8, ["channelId", "dateRange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_LineChartRevenue, {
                channelId: $setup.channelId,
                dateRange: $setup.dateRange
              }, null, 8, ["channelId", "dateRange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 12,
          sm: 12,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PieChartUser, {
                channelId: $setup.channelId,
                dateRange: $setup.dateRange
              }, null, 8, ["channelId", "dateRange"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          xs: 12,
          sm: 12,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_PieChartRevenue, {
                channelId: $setup.channelId,
                dateRange: $setup.dateRange
              }, null, 8, ["channelId", "dateRange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}